import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { CamelCasedPropertiesDeep, SetRequired } from 'type-fest';

import type {
  ApplyPromo,
  BraintreeTokenViewResponse,
  CheckoutCartViewResponse,
  CheckoutResponse,
  CheckoutUpdateQuantityViewResponse,
  CheckoutViewResponse,
  ItemQuantity,
  RemoveItems,
  Submit,
  UpdateTotals,
  ValidateAddress,
  WAddCartItemRequest,
  WCartView,
  WCheckoutUpdateQuantityView,
  WGetCartViewResponse,
} from '@zola/svc-web-api-ts-client';
import { ConvertWebApiDeclaredToActual } from '@zola-helpers/client/dist/es/@types/svc-web-api';
import LogService from '@zola-helpers/client/dist/es/util/logService';
import { setShippingRequestAttributes } from '@/util/shippingHelper';
import * as ActionType from './types/CartActionTypes';
import { AppThunk } from './types';
import {
  ReceivedCartResponse,
  requestCartAction,
  receiveCartAction,
  requestUpdatedCartAction,
  CartUpdatedResponse,
  receiveUpdatedCartAction,
  receiveDeletedItemsAction,
  requestInitialCartDetailsAction,
  receiveInitialCartDetailsAction,
  requestCreditCardAction,
  receiveCreditCardAction,
  CreditCardView,
  requestShippingValidationAction,
  receiveShippingValidationAction,
} from './types/CartActionTypes';

type CheckoutErrorPayload = {
  request: CheckoutRequest;
  requestResponse: unknown;
};
export const logCheckoutError = (
  errorMessage: string,
  payload: CheckoutErrorPayload,
  cartId: string | undefined = undefined
): string => {
  const finalPayload = { ...payload, cartId, checkoutRedesign: true };
  return LogService.log(errorMessage, finalPayload);
};

const requestSubmitCheckout = () => ({
  type: ActionType.REQUEST_SUBMIT_CHECKOUT,
});

const receiveSubmitCheckout = (
  response: CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<CheckoutResponse>>
) => ({
  type: ActionType.RECEIVE_SUBMIT_CHECKOUT,
  payload: response.data,
});

type WithoutBaseRequest<T> = Omit<T, 'user_agent' | 'ip_address' | 'user_id' | 'store'>;
export type CheckoutRequest = CamelCasedPropertiesDeep<
  Omit<
    WithoutBaseRequest<Submit>,
    'guest' | 'impact_radius_click_id' | 'order_key' | 'post_auth_click_id'
  >
>;

export const submitCheckout =
  (body: CheckoutRequest): AppThunk<Promise<ReturnType<typeof receiveSubmitCheckout>>> =>
  (dispatch, getState) => {
    dispatch(requestSubmitCheckout());

    return ApiService.post<
      CamelCasedPropertiesDeep<ConvertWebApiDeclaredToActual<CheckoutResponse>>,
      CheckoutRequest
    >('/web-registry-api/v1/checkout', body)
      .then((json) => dispatch(receiveSubmitCheckout(json)))
      .catch((error) => {
        const { cart } = getState();
        const cartId = cart && cart.checkoutData ? cart.checkoutData.cartId : undefined;
        const errorPayload = {
          request: body,
          requestResponse: error.response,
        };
        const errorId = logCheckoutError(error.message, errorPayload, cartId);
        dispatch(toastsActions.negative({ headline: `${error.message} (Support ID: ${errorId})` }));
        throw error;
      });
  };

export type UpdateTotalsRequest = SetRequired<
  CamelCasedPropertiesDeep<WithoutBaseRequest<UpdateTotals>>,
  'isExpedited' | 'isRushed'
>;

/**
 * Updates cart totals based on zipcode and delivery method
 */
export const updateTotals =
  (body: UpdateTotalsRequest): AppThunk<Promise<CartUpdatedResponse>> =>
  (dispatch) => {
    return ApiService.put<CamelCasedPropertiesDeep<CheckoutCartViewResponse>>(
      `/web-registry-api/v1/checkout/totals`,
      body
    )
      .then((response) => response.data)
      .then((cartView) => {
        dispatch(receiveUpdatedCartAction(cartView));
        return cartView;
      });
  };

const removeItems =
  (itemIds: string[]): AppThunk<Promise<ReceivedCartResponse>> =>
  (dispatch) => {
    dispatch(requestCartAction());
    return ApiService.post<CamelCasedPropertiesDeep<WGetCartViewResponse>>(
      `/web-registry-api/v1/cart/items`,
      { itemIds }
    )
      .then((response) => response.data)
      .then((cartView) => {
        dispatch(receiveCartAction(cartView));
        return cartView;
      });
  };

export const getCart = (): AppThunk<Promise<ReceivedCartResponse>> => (dispatch) => {
  dispatch(requestCartAction());

  return ApiService.get<CamelCasedPropertiesDeep<WGetCartViewResponse>>('/web-registry-api/v1/cart')
    .then((response) => response.data)
    .then((cartView) => {
      if (cartView?.items && cartView.items.length > 0) {
        // if cart has out of stock items, delete them and return updated result
        const OUT_OF_STOCK = 'out of stock';
        const itemsToDelete = cartView.items.filter(
          (item) =>
            item.availability && item.availability?.stockStatus?.toLowerCase() === OUT_OF_STOCK
        );
        if (itemsToDelete.length > 0) {
          dispatch(receiveDeletedItemsAction(itemsToDelete));
          const ids = itemsToDelete.map((item) => item.id) as string[];
          return dispatch(removeItems(ids));
        }
      }
      dispatch(receiveCartAction(cartView));
      return cartView;
    });
};

export const getInitialCartDetails = (): AppThunk<Promise<void>> => (dispatch) => {
  dispatch(requestInitialCartDetailsAction());

  return ApiService.get<CamelCasedPropertiesDeep<CheckoutViewResponse>>(
    '/web-registry-api/v1/checkout'
  )
    .then((response) => response.data)
    .then((details) => {
      dispatch(receiveInitialCartDetailsAction(details));
    });
};

type ValidateAddressRequest = CamelCasedPropertiesDeep<WithoutBaseRequest<ValidateAddress>>;

export const checkShippingAddress =
  (body: ValidateAddressRequest, allowBypass: boolean): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(requestShippingValidationAction());

    return ApiService.post<CamelCasedPropertiesDeep<CheckoutResponse>>(
      '/web-registry-api/v1/checkout/validate-address',
      body
    ).then((checkoutResponse) => {
      if (checkoutResponse.data && checkoutResponse.data.userMessage) {
        dispatch(receiveShippingValidationAction({ ...checkoutResponse, allowBypass }));
        throw Error(checkoutResponse.data.userMessage);
      }
      dispatch(receiveShippingValidationAction({ ...checkoutResponse, allowBypass }));
    });
  };

type CreditCardResponse = {
  data: CreditCardView[]; // from web-zola, yee-haw
};

export const getDefaultCreditCard = (): AppThunk<Promise<void>> => (dispatch) => {
  dispatch(requestCreditCardAction());
  // This is a web-zola route (CreditCardView)
  return ApiService.get<CreditCardResponse>('/api/v0/cart/credit-cards/get-credit-cards').then(
    (response) => {
      dispatch(receiveCreditCardAction(response.data));
    }
  );
};

const requestBraintreeToken = () => ({
  type: ActionType.REQUEST_BRAINTREE_TOKEN,
});

const receiveBraintreeToken = (braintreeToken: string | undefined) => ({
  type: ActionType.RECEIVE_BRAINTREE_TOKEN,
  payload: braintreeToken,
});

export const getBraintreeToken =
  (): AppThunk<Promise<ReturnType<typeof receiveBraintreeToken>>> => (dispatch) => {
    dispatch(requestBraintreeToken());

    return ApiService.get<CamelCasedPropertiesDeep<BraintreeTokenViewResponse>>(
      '/web-registry-api/v1/checkout/braintree/token'
    ).then((json) => dispatch(receiveBraintreeToken(json.data?.braintreeToken)));
  };

const requestRemovePromo = () => ({
  type: ActionType.REQUEST_REMOVE_PROMO,
});

export const removePromo =
  (
    isExpedited: boolean,
    isRushed: boolean,
    promoCode: string,
    zipCode: string,
    registryId: string
  ): AppThunk<Promise<CartUpdatedResponse>> =>
  (dispatch) => {
    dispatch(requestRemovePromo());

    const baseReqAttributes = {
      ...setShippingRequestAttributes(isExpedited, isRushed),
      promoCode,
    };

    return ApiService.post<
      CamelCasedPropertiesDeep<CheckoutCartViewResponse>,
      CamelCasedPropertiesDeep<WithoutBaseRequest<UpdateTotals>>
    >(
      '/web-registry-api/v1/checkout/remove-promo',
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    )
      .then((response) => response.data)
      .then((checkoutCartView) => {
        dispatch(receiveUpdatedCartAction(checkoutCartView));
        return checkoutCartView;
      })
      .catch((response) => {
        const error =
          response.response && response.response.error && response.response.error.message;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };

const requestApplyPromo = () => ({
  type: ActionType.REQUEST_APPLY_PROMO,
});

export const applyPromo =
  (
    isExpedited: boolean,
    isRushed: boolean,
    promoCode: string,
    zipCode: string,
    registryId: string
  ): AppThunk<Promise<CartUpdatedResponse>> =>
  (dispatch) => {
    dispatch(requestApplyPromo());

    const body: CamelCasedPropertiesDeep<ApplyPromo> = {
      ...setShippingRequestAttributes(isExpedited, isRushed),
      promoCode,
    };
    if (registryId) {
      body.registryId = registryId;
    } else {
      body.zipCode = zipCode;
    }

    return ApiService.post<
      CamelCasedPropertiesDeep<CheckoutCartViewResponse>,
      CamelCasedPropertiesDeep<ApplyPromo>
    >('/web-registry-api/v1/checkout/apply-promo', body)
      .then((response) => response.data)
      .then((checkoutCartView) => {
        dispatch(receiveUpdatedCartAction(checkoutCartView));
        return checkoutCartView;
      })
      .catch((response) => {
        throw response?.response?.error?.trigger || response;
      });
  };

const requestRemoveCredits = () => ({
  type: ActionType.REQUEST_REMOVE_CREDITS,
});

export const removeCredits =
  (
    isExpedited: boolean,
    isRushed: boolean,
    zipCode: string,
    registryId: string
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(requestRemoveCredits());

    // Note: as of the TS conversion time, none of these attributes are actually used on the backend to
    // find the cart and remove the credits.  The cart is found by the user's session / user id
    const body = {
      ...setShippingRequestAttributes(isExpedited, isRushed),
      zipCode,
      registryId,
    };

    return ApiService.post<CamelCasedPropertiesDeep<CheckoutCartViewResponse>, UpdateTotalsRequest>(
      '/web-registry-api/v1/checkout/remove-credit',
      body
    )
      .then((response) => response.data)
      .then((checkoutCartView) => {
        dispatch(receiveUpdatedCartAction(checkoutCartView));
      })
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };

function requestApplyCredits() {
  return {
    type: ActionType.REQUEST_APPLY_CREDITS,
  };
}

export const applyCredits =
  (
    isExpedited: boolean,
    isRushed: boolean,
    zipCode: string,
    registryId: string
  ): AppThunk<Promise<CartUpdatedResponse>> =>
  (dispatch) => {
    dispatch(requestApplyCredits());

    const baseReqAttributes = setShippingRequestAttributes(isExpedited, isRushed);

    return ApiService.post<CamelCasedPropertiesDeep<CheckoutCartViewResponse>>(
      '/web-registry-api/v1/checkout/apply-credit',
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    )
      .then((response) => response.data)
      .then((cartView) => {
        dispatch(receiveUpdatedCartAction(cartView));
        return cartView;
      })
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };

const requestRemoveCreditCard = () => ({
  type: ActionType.REQUEST_REMOVE_CREDIT_CARD,
});

const receiveRemoveCreditCard = (response: { data: null }) => ({
  type: ActionType.RECEIVE_REMOVE_CREDIT_CARD,
  payload: response.data,
});

export const removeCreditCard =
  (cardId: string): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(requestRemoveCreditCard());
    return ApiService.post<{ data: null }>('/api/v0/cart/credit-cards/remove-credit-card', cardId)
      .then((json) => {
        dispatch(receiveRemoveCreditCard(json));
      })
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };

export const updateItemQuantity =
  (
    id: string,
    quantity: number,
    isExpedited: boolean,
    isRushed: boolean,
    zipCode: string,
    registryId: string
  ): AppThunk<Promise<CamelCasedPropertiesDeep<WCheckoutUpdateQuantityView> | undefined>> =>
  (dispatch) => {
    dispatch(requestUpdatedCartAction());

    const baseReqAttributes = {
      ...setShippingRequestAttributes(isExpedited, isRushed),
      quantity,
    };

    return ApiService.put<
      CamelCasedPropertiesDeep<CheckoutUpdateQuantityViewResponse>,
      CamelCasedPropertiesDeep<WithoutBaseRequest<ItemQuantity>>
    >(
      `/web-registry-api/v1/checkout/cart/item/id/${id}`,
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    )
      .then((response) => {
        if (response?.data?.errorMessage) {
          dispatch(toastsActions.negative({ headline: response.data.errorMessage }));
        }
        dispatch(receiveUpdatedCartAction(response.data!.cart));
        return response.data;
      })
      .catch((error) => {
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error;
      });
  };

export const removeSuiteItems =
  (
    itemIds: string[],
    isExpedited: boolean,
    isRushed: boolean,
    zipCode: string,
    registryId: string
  ): AppThunk<Promise<CartUpdatedResponse>> =>
  (dispatch) => {
    dispatch(requestUpdatedCartAction());

    const body = {
      itemIds,
      ...setShippingRequestAttributes(isExpedited, isRushed),
      zipCode: !registryId ? zipCode : undefined,
      registryId: registryId || undefined,
    };

    return ApiService.post<
      CamelCasedPropertiesDeep<CheckoutCartViewResponse>,
      CamelCasedPropertiesDeep<WithoutBaseRequest<RemoveItems>>
    >(`/web-registry-api/v1/checkout/cart/items`, body)
      .then((response) => response.data)
      .then((checkoutCartView) => {
        dispatch(receiveUpdatedCartAction(checkoutCartView));
        return checkoutCartView;
      });
  };

export const removeItem =
  (
    id: string,
    isExpedited: boolean,
    isRushed: boolean,
    zipCode: string,
    registryId: string
  ): AppThunk<Promise<CartUpdatedResponse>> =>
  (dispatch) => {
    dispatch(requestUpdatedCartAction());

    const baseReqAttributes = setShippingRequestAttributes(isExpedited, isRushed);

    return ApiService.post<
      CamelCasedPropertiesDeep<CheckoutCartViewResponse>,
      CamelCasedPropertiesDeep<WithoutBaseRequest<UpdateTotals>>
    >(
      `/web-registry-api/v1/checkout/cart/item/id/${id}`,
      !registryId ? { ...baseReqAttributes, zipCode } : { ...baseReqAttributes, registryId }
    )
      .then((response) => response.data)
      .then((checkoutCartView) => {
        dispatch(receiveUpdatedCartAction(checkoutCartView));
        return checkoutCartView;
      });
  };

const requestAddItemToCart = () => ({
  type: ActionType.ADD_ITEM_TO_CART,
});

const receiveAddItemToCart = (json: WCartView) => ({
  type: ActionType.ADDED_ITEM_TO_CART,
  payload: json,
});

export const addItemToCart =
  (
    body: CamelCasedPropertiesDeep<WithoutBaseRequest<WAddCartItemRequest>>
  ): AppThunk<Promise<ReturnType<typeof receiveAddItemToCart>>> =>
  (dispatch) => {
    dispatch(requestAddItemToCart());

    // Note, this does not camel case the response
    // Also note: the server sends .data not the response, which is different
    // from most of the other actions in this file
    return ApiService.post<
      WCartView,
      CamelCasedPropertiesDeep<WithoutBaseRequest<WAddCartItemRequest>>
    >('/web-registry-api/v1/cart/item', body)
      .then((json) => dispatch(receiveAddItemToCart(json)))
      .catch((response) => {
        const error = response.response && response.response.error && response.response.error;
        dispatch(toastsActions.negative({ headline: error.message }));
        throw error || response;
      });
  };
