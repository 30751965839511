import experimentFlagsHelper from '@zola-helpers/client/dist/es/util/experimentFlagsHelper';
import { UserContext } from '@zola-helpers/server/dist/es/@types/userContext';

import experimentsFlagsHelperSSR from '@zola-helpers/server/dist/es/experiments';

const EXPERIMENTS = [
  'hdyhau-subway', // Release ramp to ask if users heard about us on the subway, kept in case we stop advertising on the subway
  'holiday-shops', // Seasonal shop (non-card) features,
  'marketplace-clp-lp-links-to-srp-v2', // PE-989 - update vendor footer link to location based SRP link
  'checkout-better-hierarchy-v2', // PE-1270 - Update checkout hierarchy to be more user friendly
  'checkout-payment-intents-release-ramp',
  'logrocket-checkout', // LogRocket sessions for checkout
] as const;

type RegistryExperiment = (typeof EXPERIMENTS)[number];

type FallbackVariations = Record<
  RegistryExperiment,
  ReturnType<typeof experimentFlagsHelper.createExperimentFlags>
>;

// Experiment Keys
export const HDYHAU_SUBWAY = 'hdyhau-subway'; // Release ramp to ask if users heard about us on the subway, kept in case we stop advertising on the subway
export const HOLIDAY_SHOPS = 'holiday-shops'; // Seasonal shop (non-card) features
export const CLP_LP_LINKS_TO_SRP_V2 = 'marketplace-clp-lp-links-to-srp-v2'; // PE-1348
export const CHECKOUT_BETTER_HIERARCHY = 'checkout-better-hierarchy-v2'; // PE-1270 - Update checkout hierarchy to be more user friendly
export const LOGROCKET_CHECKOUT = 'logrocket-checkout'; // LogRocket sessions for checkout

const FALLBACK_VARIATIONS: FallbackVariations = EXPERIMENTS.reduce((result, experiment) => {
  return {
    ...result,
    [experiment]: experimentFlagsHelper.createExperimentFlags(),
  };
}, {} as FallbackVariations);

const { isUnderTest, getExperimentVariation, isInExperiment, getFlag } =
  experimentFlagsHelper.makeExperimentFlags(FALLBACK_VARIATIONS);

const EXPERIMENT_FLAGS = {
  isUnderTest: (
    experimentKey: RegistryExperiment,
    userContext: UserContext | null,
    triggerExperimentViewed?: boolean
  ) => isUnderTest(experimentKey, userContext, triggerExperimentViewed),
  getExperimentVariation: (
    experimentName: RegistryExperiment,
    userContext: UserContext | null,
    triggerExperimentViewed?: boolean
  ) => getExperimentVariation(experimentName, userContext, triggerExperimentViewed),
  isInExperiment: (
    experimentName: RegistryExperiment,
    userContext: UserContext | null,
    triggerExperimentViewed?: boolean
  ) => isInExperiment(experimentName, userContext, triggerExperimentViewed),
  getFlag: (experimentName: RegistryExperiment, userContext: UserContext | null) =>
    getFlag(experimentName, userContext),
};

export default EXPERIMENT_FLAGS;

const FALLBACK_VARIATIONS_SSR = EXPERIMENTS.reduce((result, experiment) => {
  return {
    ...result,
    [experiment]: experimentsFlagsHelperSSR.createExperimentFlags(),
  };
}, {} as FallbackVariations);

const { getExperimentVariation: getExperimentVariationSSR } =
  experimentsFlagsHelperSSR.makeExperimentFlags(FALLBACK_VARIATIONS_SSR);

export const EXPERIMENT_FLAGS_SSR = {
  getExperimentVariation: (
    experimentName: RegistryExperiment,
    userContext: UserContext | null,
    cookies: { [key: string]: string }
  ) => getExperimentVariationSSR(experimentName, userContext as UserContext, cookies),
};
